menu = {
	elements: {
		overlay: null,
		nav: null,
		navItem: null,
		control: null,
		opened: false,
		header: null
	},

	__prepareVars: function() {
		this.elements.overlay = $('.overlay');
		this.elements.nav = $('.nav');
		this.elements.navItem = $('.nav__link');
		this.elements.control = $('.menuControl');
		this.elements.header = $('.header');

		this.__addListeners();
	},

	__addListeners: function() {
		var _self = this;

		$(this.elements.control).on('click', function(e) {
			if(_self.elements.opened === false) {
				_self.elements.overlay.addClass('animated');
				_self.elements.nav.addClass('animated');
				_self.elements.header.addClass('opened');
			} else {
				_self.elements.overlay.removeClass('animated');
				_self.elements.nav.removeClass('animated');
				_self.elements.header.removeClass('opened');
			}

			_self.elements.opened = !_self.elements.opened;
		});

		$(this.elements.navItem).on('click', function(e) {
			_self.elements.overlay.removeClass('animated');
			_self.elements.nav.removeClass('animated');
			_self.elements.header.removeClass('opened');
			_self.elements.opened = !_self.elements.opened;
		});
	},

	init: function() {
		this.__prepareVars();
	}
};

module.exports = menu;