/**
 * Created by Zdeněk Suda @ zdeneksuda.cz on 20.12.15.
 */

var helper = require('./helper');
var anchor = require('./anchor');
var menu = require('./menu');
var contact = require('./contact');

$(window).on('load', function() {
	window.helper = helper;
	anchor.init();
	menu.init();
	contact.init();

	detectScrolling();
});

function detectScrolling() {
	$(window).on('scroll', function() {
		if(window.helper.scrollTop() > 5) {
			$('body, header').addClass('animated');
		} else {
			$('body, header').removeClass('animated');
		}
	});
}