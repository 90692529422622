contact = {
	elements: {
		overlay: null,
		wrap: null,
		content: null,
		control: null,
		opened: false,
	},

	__prepareVars: function() {
		this.elements.overlay = $('.overlayContact');
		this.elements.control = $('.contactControl');
		this.elements.wrap = $('.overlayWrap');
		// this.elements.content = $();

		this.__addListeners();
	},

	__addListeners: function() {
		var _self = this;

		$(this.elements.control).on('click', function(e) {
			if(_self.elements.opened === false) {
				_self.elements.wrap.show().animate({
					'opacity': 1
				}, 150);

				_self.elements.overlay.show().animate({
					'opacity': 1
				}, 150);
			} else {
				_self.elements.wrap.animate({
					'opacity': 0
				}, 150, function () {
					$(this).hide();
				});

				_self.elements.overlay.animate({
					'opacity': 0
				}, 150, function () {
					$(this).hide();
				});
			}

			_self.elements.opened = !_self.elements.opened;
		});
	},

	init: function() {
		this.__prepareVars();
	}
};

module.exports = contact;